/* eslint-disable import/no-named-as-default-member */
import React from 'react'
import _ from 'lodash'
import GeneralGaugeSelector from '../../../components/Gagues/Functions/GeneralGaugeSelector'
import SensorDataRendering from '../../../components/Sensors/Functions/SensorDataRendering'
import VariableDataRendering from '../../../components/Variables/Function/VariableMainRendering'
import Config from '../../../config'

export default function DashboardMonitorComponent({
  currentStation,
  currentStation2,
  handleEditUserFavorite,
  user,
}) {
  let orderedGauge = _.orderBy(currentStation.gauges, 'orderLevel', 'asc')
  //console.log('currentStation2', currentStation2)

  return (
    <div>
      <div className="grid grid-cols-1 gap-x-4 gap-y-1 lg:grid-cols-5 md:gid-cols-3 mr-6 mb-4">
        {_.map(currentStation, (e, index) => {
          if (e.gaugeSource === Config.TypeOfSource.GaugeSource.sensor) {
            return (
              <GeneralGaugeSelector
                gaugeData={e}
                key={index}
                stationData={currentStation2}
                staticValue={SensorDataRendering(currentStation2, e)}
                handleEditUserFavourite={handleEditUserFavorite}
                user={user}
              />
            )
          } else {
            return (
              <GeneralGaugeSelector
                gaugeData={e}
                key={index}
                stationData={currentStation}
                staticValue={VariableDataRendering(
                  currentStation,
                  e.variableId
                )}
                handleEditUserFavourite={handleEditUserFavorite}
                user={user}
              />
            )
          }
        })}
        {_.map(orderedGauge, (gauge, index) => {
          if (gauge.gaugeSource === Config.TypeOfSource.GaugeSource.sensor) {
            return (
              <GeneralGaugeSelector
                gaugeData={gauge}
                key={index}
                stationData={currentStation}
                staticValue={SensorDataRendering(currentStation, gauge)}
                handleEditUserFavourite={handleEditUserFavorite}
                user={user}
              />
            )
          } else {
            return (
              <GeneralGaugeSelector
                gaugeData={gauge}
                key={index}
                stationData={currentStation}
                staticValue={VariableDataRendering(
                  currentStation,
                  gauge.variableId
                )}
                handleEditUserFavourite={handleEditUserFavorite}
                user={user}
              />
            )
          }
        })}
      </div>
    </div>
  )
}
